export default [
  {
    key: "client_name",
    label: "Client",
    sortable: false,
    visible: true,
  },
  {
    key: "details",
    label: "Details",
    thClass: " text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "type_send",
    label: "Type",
    thClass: " text-center",
    class: "text-center",
    sortable: false,
    visible: false,
  },
  {
    key: "tu",
    label: "TU",
    thClass: "text-center ",
    tdClass: "text-center",
    sortable: false,
    visible: false,
  },
  {
    key: "ex",
    label: "EX",
    thClass: "text-center ",
    tdClass: "text-center",
    sortable: false,
    visible: false,
  },
  {
    key: "eq",
    label: "EQ",
    thClass: "text-center ",
    tdClass: "text-center",
    sortable: false,
    visible: false,
  },
  {
    key: "cr_date",
    label: "Date of CR",
    sortable: false,
    visible: false,
  },
  {
    key: "cr",
    label: "CR",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "program",
    label: "Program",
    thClass: " text-center",
    sortable: false,
    visible: false,
  },
  {
    key: "created_by",
    label: "Created By",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
  },
  {
    key: "advisor_name",
    label: "Requested by",
    sortable: false,
    visible: true,
  },
  {
    key: "sent_by",
    label: "Completed by",
    thClass: " text-center",
    visible: true,
  },
  {
    key: "action",
    label: "ACTION",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
    visible: true,
    thStyle: { width: "300px" },
  },
  {
    key: "tracking",
    label: "Tracking",
    thClass: "text-center ",
    visible: true,
  },
  {
    key: "deleted_by",
    label: "Deleted By",
    thClass: "text-center ",
    visible: false,
  },
];
