var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('filter-slot',{staticClass:"border-top-primary border-3 border-table-radius p-0 m-0",attrs:{"filter":_vm.filtersC,"filter-principal":_vm.filterPrincipal,"totalRows":_vm.totalRows,"paginate":_vm.paginate,"startPage":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['directsRoundListTable'].refresh()},"reset-all-filters":_vm.refreshTable}},[_c('b-table',{ref:"directsRoundListTable",attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.myProvider,"fields":_vm.filteredFields,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","busy":_vm.isBusy,"sticky-header":"100vh"},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2",staticStyle:{"color":"#dfb15a !important"}},[_c('b-spinner',{staticClass:"align-middle",attrs:{"type":"grow"}}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(item){return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('div',[_c('router-link',{attrs:{"target":"_blank","to":{
                  name: _vm.clientDashboardRouteName,
                  params: {
                    idClient: ("" + (item.item.client_account_id)),
                  },
                }}},[_vm._v(" "+_vm._s(item.item.client_name)+" ")])],1)]),_c('status-account',{attrs:{"account":item.item}}),_c('span',{staticClass:"d-block",staticStyle:{"margin-left":"18px"}},[_vm._v(" "+_vm._s(item.item.account)+" ")])]}},{key:"cell(program)",fn:function(item){return [_c('div',{staticClass:"text-center text-uppercase text-info"},[_vm._v(" "+_vm._s(item.item.program)+" ")])]}},{key:"cell(created_by)",fn:function(item){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.item.created_by)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.item.created_at))+" ")])]}},{key:"cell(advisor)",fn:function(item){return [_c('div',[_vm._v(_vm._s(item.item.advisor_name))])]}},{key:"cell(deleted_by)",fn:function(item){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.item.deleted_by || "-")+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.item.deleted_at))+" ")])]}},{key:"cell(cr)",fn:function(item){return [_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"gap":"10px"}},[_c('div',{staticStyle:{"width":"20px"}},[(item.item.route_pdf != null)?_c('amg-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('View PDF'),expression:"'View PDF'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer mt-0 mr-0 bigger text-danger",staticStyle:{"height":"18px","width":"18px"},attrs:{"icon":"FilePdfIcon"},on:{"click":function($event){return _vm.viewPdf(item.item)}}}):_vm._e()],1)])]}},{key:"cell(details)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-center text-primary",staticStyle:{"margin-top":"5px"}},[(_vm.moduleId != 28 || (_vm.moduleId == 28 && !_vm.isInPendingTab))?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('View Details'),expression:"'View Details'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer",attrs:{"variant":"info"},on:{"click":function($event){return _vm.openModalAddRound(item)}}},[_vm._v(" "+_vm._s(item.sumtr + item.sumeq + item.sumex)+" ")]):_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.sumtr + item.sumeq + item.sumex)+" ")])],1)]}},{key:"cell(type_send)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"margin-top":"5px"}},[_c('b-badge',{attrs:{"variant":item.type_send === 'PHONE' ? 'light-info' : 'light-primary'}},[_vm._v(" "+_vm._s(item.type_send)+" ")])],1)]}},{key:"cell(sent_by)",fn:function(item){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.item.sent_by || "-")+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.item.date_send))+" ")])]}},{key:"cell(action)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex gap-5 justify-content-center"},[_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.openModalAddRound(item)}}},[_c('feather-icon',{attrs:{"icon":"SendIcon","size":"16"}}),_vm._v(" Process ")],1),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.openModalEditRoundLetter(
                  item.id,
                  item.client_account_id,
                  item.account,
                  item.client_name
                )}}},[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"16"}}),_vm._v(" Edit ")],1),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteRoundLetter(item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon","size":"16"}}),_vm._v(" Delete ")],1)],1)]}},{key:"cell(tracking)",fn:function(item){return [_c('div',{staticClass:"text-center"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Tracking'),expression:"'Tracking'",modifiers:{"hover":true,"right":true}}],staticClass:"text-info cursor-pointer",attrs:{"icon":"ListIcon","size":"20"},on:{"click":function($event){return _vm.openModalTracking(item.item)}}})],1)]}}])})],1)],1),(_vm.modalTrackingState)?_c('modal-tracking',{attrs:{"dataClient":_vm.dataClient},on:{"hideModal":function($event){_vm.modalTrackingState = false}}}):_vm._e(),(_vm.modalProcessDirect)?_c('modal-process-direct',{attrs:{"dataClient":_vm.dataClient,"direct":_vm.directType,"validedit":_vm.validEdit},on:{"hideModal":_vm.closeModalProcessDirect,"update":_vm.refreshTable}}):_vm._e(),(_vm.modalEditRoundLetterState)?_c('modal-edit-round-letter',{attrs:{"idround":_vm.ncrId,"idaccount":_vm.clientAccountId,"account":_vm.accountId,"nameClient":_vm.nameClient,"direct":true},on:{"updateTable":_vm.refreshTable,"hideModal":function($event){_vm.modalEditRoundLetterState = false}}}):_vm._e(),(_vm.modalAddRoundLetterState)?_c('add-round-modal',{attrs:{"data-round":_vm.itemClient,"validate-modal":_vm.validateModal,"from-grid":true},on:{"close":function($event){return _vm.closeRoundModal()},"emitOpenModalDone":_vm.modalOpenModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }