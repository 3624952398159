<template>
  <b-modal
    ref="addRoundModal"
    no-close-on-backdrop
    :hide-footer="!showViewDispute && !G_IS_FOOTER_MODAL_ACTIVE"
    title-class="h3 text-white font-weight-bolder"
    :title="title"
    scrollable
    size="xmd"
    no-close-on-esc
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          {{ title }}
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <div>
      <div>
        <b-row class="mb-2">
          <b-col sm="12" md="6" lg="6">
            <b-input-group class="mt-1">
              <b-input-group-prepend variant="light" style="width: 30%">
                <b-button
                  :class="isDarkSkin ? 'dark-btn' : 'light-btn'"
                  class="w-100 disableBtn"
                  readonly
                  role="text"
                  >ACCOUNT</b-button
                >
              </b-input-group-prepend>

              <b-form-input
                v-model="dataRound.account"
                class="text-center"
                :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                readonly
              />
            </b-input-group>
          </b-col>
          <b-col sm="12" md="6" lg="6">
            <b-input-group class="mt-1">
              <b-input-group-prepend variant="light" style="width: 30%">
                <b-button
                  :class="isDarkSkin ? 'dark-btn' : 'light-btn'"
                  class="w-100 disableBtn"
                  readonly
                  role="text"
                  >CLIENT</b-button
                >
              </b-input-group-prepend>

              <b-form-input
                v-model="dataRound.clientName"
                class="text-center"
                :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                readonly
              />
            </b-input-group>
          </b-col>
          <template v-if="moduleId == 11 || moduleId == 25 || moduleId == 28">
            <b-col sm="12" md="6" lg="6">
              <b-input-group class="mt-1">
                <b-input-group-prepend variant="light" style="width: 30%">
                  <b-button
                    :class="isDarkSkin ? 'dark-btn' : 'light-btn'"
                    class="w-100 disableBtn"
                    readonly
                    role="text"
                    >DOB</b-button
                  >
                </b-input-group-prepend>

                <b-form-input
                  :value="dataRound.clientData.dob | myGlobal"
                  class="text-center"
                  :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                  readonly
                />
              </b-input-group>
            </b-col>
            <b-col sm="12" md="6" lg="6">
              <b-input-group class="mt-1">
                <b-input-group-prepend variant="light" style="width: 30%">
                  <b-button
                    :class="isDarkSkin ? 'dark-btn' : 'light-btn'"
                    class="w-100 disableBtn"
                    readonly
                    role="text"
                    >{{
                      ssnClient != ""
                        ? "SSN"
                        : itinClient != ""
                        ? "ITIN"
                        : otherClient != ""
                        ? "OTHER"
                        : "SSN"
                    }}</b-button
                  >
                </b-input-group-prepend>

                <b-form-input
                  v-model="clientDataSsnItinOther"
                  class="text-center"
                  :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                  readonly
                />
              </b-input-group>
            </b-col>
            <b-col sm="12" md="6" lg="6">
              <b-input-group class="mt-1">
                <b-input-group-prepend variant="light" style="width: 30%">
                  <b-button
                    :class="isDarkSkin ? 'dark-btn' : 'light-btn'"
                    class="w-100 disableBtn"
                    readonly
                    role="text"
                    >ADDRESS</b-button
                  >
                </b-input-group-prepend>

                <b-form-input
                  v-model="dataRound.clientData.address"
                  class="text-center"
                  :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                  readonly
                />
              </b-input-group>
            </b-col>
            <b-col sm="12" md="6" lg="6">
              <b-input-group class="mt-1">
                <b-input-group-prepend variant="light" style="width: 30%">
                  <b-button
                    :class="isDarkSkin ? 'dark-btn' : 'light-btn'"
                    class="w-100 disableBtn"
                    readonly
                    role="text"
                    style="white-space: nowrap;"
                    > ORIGIN COUNTRY</b-button
                  >
                </b-input-group-prepend>

                <b-form-input
                  v-model="dataRound.clientData.origin_country"
                  class="text-center"
                  :class="isDarkSkin ? 'bg-transparent' : 'bg-white'"
                  readonly
                />
              </b-input-group>
            </b-col>
          </template>
        </b-row>
      </div>
      <details-component2
        :data-round="detailRoundData"
        :client-data="dataRound"
        :validate-state-created="true"
        :validate-modal="validateModal"
        :show-all-letters="showAllLetters"
        :from-grid="fromGrid"
        @viewpdf="showViewPdf"
        @close="closeDetailsModal"
        @closeModalMain="closeModal"
        @setTab="setTab"
      />
    </div>
    <template #modal-footer>
      <div v-if="!G_IS_FOOTER_MODAL_ACTIVE">
        <div>DONE BY:</div>
        <div class="font-weight-bolder">
          {{ dispute.name_user_recomendation }} |
          {{ dispute.created_recommendation | myGlobalDay }}
        </div>
      </div>
      <div v-if="G_IS_FOOTER_MODAL_ACTIVE">
        <b-button variant="success" @click="editDelAccountInUni">
          <feather-icon icon="SaveIcon" />
          SAVE</b-button
        >
      </div>
    </template>
  </b-modal>
</template>
<script>
import dataFields from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/fields.data";

import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapActions, mapGetters, mapMutations } from "vuex";
import TrackingModalRo from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/tracking-modal-ro/TrackingModalRo.vue";
import CreateDispute from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/CreateDispute.vue";
import DetailsComponent from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/details-modal/DetailsComponent.vue";
import DetailsComponent2 from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/details-modal/DetailsComponent2.vue";
import ShowLettersByBureau from "@/views/commons/components/clients/dashboard/options/dis-module/modals/show-letters-by-bureu/ShowLettersByBureau.vue";
import ViewDisputeComponentNew from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/view-dispute-modal/ViewDisputeComponentNew.vue";
import ProcessComponent from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/ProcessComponent.vue";
import CreateCRRoundModal from "@/views/commons/components/clients/dashboard/options/dis-module/modals/rounds-modal/modals/create-cr-round/CreateCRRoundModal.vue";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import moment from "moment";

export default {
  components: {
    TrackingModalRo,
    CreateDispute,
    DetailsComponent,
    ShowLettersByBureau,
    ViewDisputeComponentNew,
    DetailsComponent2,
    ProcessComponent,
    CreateCRRoundModal,
  },
  props: {
    dataRound: {
      type: Object,
      default: null,
    },
    validateModal: {
      type: Number,
      default: null,
    },
    showAllLetters: {
      type: Boolean,
      default: true,
    },
    fromGrid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailsModalOn: false,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      comments: null,
      fields: dataFields,
      items: {},
      trackingModalOn: false,
      trackingData: {},
      modalDisputeOn: false,
      dispute: {},
      createDisputeData: {},
      dataDispute: {},
      titleRound: "",
      detailRoundData: {},
      showLetterByBureau: false,
      showViewDispute: false,
      showProcessModal: false,
      processData: {},
      createCRRoundModal: false,
      roundLetterData: { id: null },
      footerSave: false,
      editingRound: false,
      clientDob: "",
      ssnClient: "",
      origin_country: "",
      addressClient: "",
      itinClient: "",
      otherClient: "",
      clientDataSsnItinOther: "",
      totalRounds: null,
    };
  },
  computed: {
    allRoundsHasBeenCreated() {
      return this.items.length == 6;
    },
    validationOpenTable() {
      if (this.modalDisputeOn === true) {
        return false;
      }
      if (this.trackingModalOn === true) {
        return false;
      }
      if (this.detailsModalOn === true) {
        return false;
      }
      if (this.showLetterByBureau === true) {
        return false;
      }
      if (this.showViewDispute === true) {
        return false;
      }
      if (this.showProcessModal === true) {
        return false;
      }
      return this.createCRRoundModal !== true;
    },
    title() {
      if (this.modalDisputeOn === true) {
        return "UPDATE REQUEST";
      }
      if (this.trackingModalOn === true) {
        return "TRACKING";
      }
      if (this.detailsModalOn === true) {
        // return this.detailRoundData.title;
        return "DETAILS OF DIRECT DISPUTE";
      }
      if (this.createCRRoundModal === true) {
        if (this.editingRound === true) {
          return "EDIT DIRECT LETTER";
        }
        return "CREATE DIRECT";
      }
      return "ROUNDS";
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_IS_FOOTER_MODAL_ACTIVE:
        "SpecialistsDigitalRoundLettersStore/G_IS_FOOTER_MODAL_ACTIVE",
      G_BUREAU_LETTERS_DATA:
        "SpecialistsDigitalRoundLettersStore/G_BUREAU_LETTERS_DATA",
      G_VALID_DONE_DIRECT:
        "AnalystDepartamentDirectRoundStore/G_VALID_DONE_DIRECT",
      G_EDIT_LETTER_MODAL:
        "SpecialistsDigitalRoundLettersStore/G_EDIT_LETTER_MODAL",
      G_CREATE_DETAIL_LETTERS_MODAL:
        "SpecialistsDigitalRoundLettersStore/G_CREATE_DETAIL_LETTERS_MODAL",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.toggleModal("addRoundModal");
    if (this.validateModal === 1) {
      this.fields[4].visible = true;
    }
    if (this.validateModal === 1) {
      this.fields[5].visible = true;
    }
    if (this.validateModal === 0 || this.validateModal === 3) {
      this.fields[7].visible = true;
    }
    if (this.validateModal === 0 || this.validateModal === 3) {
      this.fields[8].visible = true;
    }
    if ([5, 6, 22, 7, 11, 25, 20].includes(this.moduleId)) {
      this.fields[10].visible = true;
    }

    this.clientDob = this.dataRound.clientData.dob
      ? moment(this.dataRound.clientData.dob).format("MM/DD/YYYY")
      : "";
    this.ssnClient = this.dataRound.clientData.ssn
      ? this.dataRound.clientData.ssn
      : "";
    this.itinClient = this.dataRound.clientData.itin
      ? this.dataRound.clientData.itin
      : "";
    this.otherClient = this.dataRound.clientData.other
      ? this.dataRound.clientData.other
      : "";
    this.origin_country = this.dataRound.clientData.origin_country
      ? this.dataRound.clientData.origin_country
      : "";
    this.addressClient = this.dataRound.clientData.address
      ? this.dataRound.clientData.address
      : "";
    this.clientDataSsnItinOther = this.ssnClient
      ? this.ssnClient
      : this.itinClient
      ? this.itinClient
      : this.otherClient
      ? this.otherClient
      : "";
    if (this.dataRound.isDirect) {
      const { id, title, format } = this.dataRound.isDirect;
      this.detailround(id, format, title);
    }
  },
  created() {
    this.M_SET_SUM_TU(this.dataRound.sumtr)
    this.M_SET_SUM_EX(this.dataRound.sumex)
    this.M_SET_SUM_EQ(this.dataRound.sumeq)
  },
  methods: {
    ...mapMutations({
      M_SET_SUM_TU: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_TU",
      M_SET_SUM_EX: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_EX",
      M_SET_SUM_EQ: "SpecialistsDigitalRoundLettersStore/M_SET_SUM_EQ",
    }),
    ...mapActions({
      A_EDIT_LETTER_MODAL:
        "SpecialistsDigitalRoundLettersStore/A_EDIT_LETTER_MODAL",
      A_IS_FOOTER_MODAL_ACTIVE:
        "SpecialistsDigitalRoundLettersStore/A_IS_FOOTER_MODAL_ACTIVE",
    }),
    openModalDone(data) {
      this.$emit("emitOpenModalDone", data.data);
    },
    showViewPdf() {
      this.closeDetailsModal();
      this.showLetterByBureau = true;
    },
    closeShowViewPdf() {
      this.detailsModalOn = true;
      this.showLetterByBureau = false;
    },
    closeDetailsModal() {
      this.detailsModalOn = false;
    },

    async openModalViewDispute(id) {
      this.dataDispute = { idDispute: id };
      this.showViewDispute = true;
      await this.getAllDataLettersDispute(id);
      await this.updateCountId(id);
    },

    setTab(){
      this.$refs.addRoundModal.$refs.content.removeAttribute("tabIndex");
    },

    async getAllDataLettersDispute(id) {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.allDataLettersDispute({
          id,
        });
        if (data.status === 200) {
          this.dispute = data.data[0];
          this.isBusy = false;
        }
      } catch (e) {
        this.removePreloader();
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    async updateCountId(id) {
      try {
        await DisputeResultsService.updateCountDisputeId({ iddispute: id });
        await this.$store.dispatch(
          "DebtSolutionClients/A_UPDATE_EVALUATED_BY_SP",
          {
            clientAccountId: this.$route.params.idClient,
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    closeModalViewDispute() {
      this.showViewDispute = false;
    },
    closeShowProcessModal() {
      this.showProcessModal = false;
    },
    openModalDispute(id) {
      this.createDisputeData = { id };
      this.modalDisputeOn = true;
    },
    closeModalDispute() {
      this.modalDisputeOn = false;
    },
    async deleteCRRound(item) {
      const confirmed = await this.showConfirmSwal();
      if (confirmed.isConfirmed) {
        try {
          const params = {
            id: item,
            has_workplan: this.dataRound.hasWorkPlanCreated,
            is_last_round: this.totalRounds === 1,
            client_account: this.dataRound.idAccount,
          };
          const response = await ClientsOptionsServices.deleteRoundLetter(
            params
          );
          if (response.status === 200) {
            this.$refs.roundLetterTable.refresh();
            this.$emit("refresh");
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    editAc() {
      //
    },
    alltracking(id) {
      this.trackingModalOn = true;
      this.trackingData = { id };
    },
    closeTrackingModalRo() {
      this.trackingModalOn = false;
    },
    openModalProcess(id, clientAccountId, sumTr, sumEx, sumEq) {
      this.processData = {
        id,
        clientAccountId,
        sumTr,
        sumEx,
        sumEq,
        number: 8,
      };
      this.showProcessModal = true;
    },
    detailround(id, format, title) {
      this.detailRoundData = {
        id,
        format,
        title,
        isDirect: Boolean(this.dataRound.isDirect),
      };
      this.detailsModalOn = true;
    },
    closeModal() {
      this.A_EDIT_LETTER_MODAL(false);
      this.M_SET_SUM_TU(0);
      this.M_SET_SUM_EX(0);
      this.M_SET_SUM_EQ(0);
      this.$emit("close");
      this.$emit("reload");
    },

    async search() {
      try {
        const params = {
          id: this.dataRound.id,
          idNrl: this.dataRound.idNrl,
          onlyRound: this.dataRound.onlyRound,
        };
        const data = await ClientsOptionsServices.searchRoundLetters(params);

        this.items = data.data;
        this.totalRounds = data.data.length;
        // Must return an array of items or an empty array if an error occurred
        if (this.items.length > 0 && !this.showAllLetters) {
          // This case applies when you are in Direct or Letter Dispute and needs to jump into
          this.detailround(
            this.items[this.items.length - 1].id,
            this.items[this.items.length - 1].format_r,
            this.items[this.items.length - 1].title_r
          );
          return;
        }
        return this.items;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    openCreateCRRoundModal(item = null) {
      this.createCRRoundModal = true;
      this.roundLetterData = item;
      this.editingRound = item !== null;
      this.footerSave = true;
    },
    closeCreateCRRoundModal(data) {
      this.createCRRoundModal = false;
      this.footerSave = false;
      if (!this.editingRound && data !== null) {
        this.detailRoundData = {
          id: data.id,
          format: data.format_r,
          title: data.title_r,
        };
        this.detailsModalOn = true;
      }
      this.$emit("refresh");
    },

    async editDelAccountInUni() {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          if (this.G_BUREAU_LETTERS_DATA.letterTable === 1) {
            const params = {
              content: this.G_BUREAU_LETTERS_DATA.content,
              id: this.G_BUREAU_LETTERS_DATA.id,
              iduser: this.currentUser.user_id,
              instruction: this.G_BUREAU_LETTERS_DATA.instruction,
              reason: this.G_BUREAU_LETTERS_DATA.reason,
              status: this.G_BUREAU_LETTERS_DATA.status,
            };
            const response =
              await ClientsOptionsServices.editPersonalInformation(params);
            if (response.status === 200) {
              this.A_EDIT_LETTER_MODAL(false);
              this.A_IS_FOOTER_MODAL_ACTIVE(false);
            }
          } else if (this.G_BUREAU_LETTERS_DATA.letterTable === 2) {
            const params = {
              iduser: this.currentUser.user_id,
              id: this.G_BUREAU_LETTERS_DATA.id,
              instruction: this.G_BUREAU_LETTERS_DATA.instruction,
              reason: this.G_BUREAU_LETTERS_DATA.reason,
            };
            const response = await ClientsOptionsServices.editDelAccountInUni(
              params
            );
            if (response.status === 200) {
              this.A_EDIT_LETTER_MODAL(false);
              this.A_IS_FOOTER_MODAL_ACTIVE(false);
            }
          } else if (this.G_BUREAU_LETTERS_DATA.letterTable === 3) {
            const params = {
              iduser: this.currentUser.user_id,
              id: this.G_BUREAU_LETTERS_DATA.id,
              instruction: this.G_BUREAU_LETTERS_DATA.instruction,
              reason: this.G_BUREAU_LETTERS_DATA.reason,
            };
            const response = await ClientsOptionsServices.editDelAccountPrUni(
              params
            );
            if (response.status === 200) {
              this.A_EDIT_LETTER_MODAL(false);
              this.A_IS_FOOTER_MODAL_ACTIVE(false);
            }
          } else if (this.G_BUREAU_LETTERS_DATA.letterTable === 4) {
            const params = {
              accountN: this.G_BUREAU_LETTERS_DATA.accountN,
              accountName_id: this.G_BUREAU_LETTERS_DATA.accountName_id,
              balance: this.G_BUREAU_LETTERS_DATA.balance,
              id: this.G_BUREAU_LETTERS_DATA.id,
              iduser: this.currentUser.user_id,
              instruction: this.G_BUREAU_LETTERS_DATA.instruction,
              reason: this.G_BUREAU_LETTERS_DATA.reason,
              status: this.G_BUREAU_LETTERS_DATA.status,
              type: this.G_BUREAU_LETTERS_DATA.type,
            };
            const response = await ClientsOptionsServices.editAccount(params);
            if (response.status === 200) {
              this.A_EDIT_LETTER_MODAL(false);
              this.A_IS_FOOTER_MODAL_ACTIVE(false);
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.dark-btn {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.light-btn {
  background-color: var(--primary-color) !important;
  color: black !important;
}
</style>
