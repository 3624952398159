<template>
  <div>
    <b-container class="p-0" fluid>
      <!-- startPage -->
      <filter-slot
        class="border-top-primary border-3 border-table-radius p-0 m-0"
        :filter="filtersC"
        :filter-principal="filterPrincipal"
        :totalRows="totalRows"
        :paginate="paginate"
        :startPage="startPage"
        :to-page="toPage"
        @reload="$refs['directsRoundListTable'].refresh()"
        @reset-all-filters="refreshTable"
      >
        <b-table
          small
          slot="table"
          no-provider-filtering
          ref="directsRoundListTable"
          :items="myProvider"
          :fields="filteredFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          :busy.sync="isBusy"
          sticky-header="100vh"
        >
          <template #table-busy>
            <div class="text-center my-2" style="color: #dfb15a !important">
              <b-spinner class="align-middle" type="grow"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(client_name)="item">
            <span class="d-flex align-items-center" style="gap: 5px">
              <div>
                <router-link
                  target="_blank"
                  :to="{
                    name: clientDashboardRouteName,
                    params: {
                      idClient: `${item.item.client_account_id}`,
                    },
                  }"
                >
                  {{ item.item.client_name }}
                </router-link>
              </div>
            </span>
            <status-account :account="item.item" />
            <span class="d-block" style="margin-left: 18px">
              {{ item.item.account }}
            </span>
          </template>
          <template #cell(program)="item">
            <div class="text-center text-uppercase text-info">
              {{ item.item.program }}
            </div>
          </template>
          <template #cell(created_by)="item">
            <div class="text-center">
              {{ item.item.created_by }}
              <br />
              {{ item.item.created_at | myGlobalDay }}
            </div>
          </template>
          <template #cell(advisor)="item">
            <div>{{ item.item.advisor_name }}</div>
          </template>

          <template #cell(deleted_by)="item">
            <div class="text-center">
              {{ item.item.deleted_by || "-" }}
              <br />
              {{ item.item.deleted_at | myGlobalDay }}
            </div>
          </template>

          <template #cell(cr)="item">
            <div class="d-flex justify-content-center" style="gap: 10px">
              <!-- <div class="text-center" v-if="isSpecialist && !isInDeletedTab">
                <router-link
                  :to="{
                    name:
                      moduleId == 28
                        ? 'specialist-report-lead'
                        : moduleId == 25
                        ? 'specialistdigital-report-lead'
                        : '',
                    params: {
                      idfile: item.item.score_id || item.item.last_score,
                      idlead: item.item.lead_id,
                      accountId: item.item.client_account_id,
                      modul: moduleId,
                      isDigital: true,
                    },
                  }"
                >
                  <feather-icon
                    size="20"
                    :class="isDarkSkin ? 'text-primary' : 'text-dark'"
                    icon="FileTextIcon"
                  />
                </router-link>
              </div> -->
              <div style="width: 20px">
                <amg-icon
                  v-if="item.item.route_pdf != null"
                  v-b-tooltip.hover.top="'View PDF'"
                  @click="viewPdf(item.item)"
                  class="cursor-pointer mt-0 mr-0 bigger text-danger"
                  icon="FilePdfIcon"
                  style="height: 18px; width: 18px"
                />
              </div>
            </div>
          </template>

          <template #cell(details)="{ item }">
            <div class="text-center text-primary" style="margin-top: 5px">
              <b-badge
                v-b-tooltip.hover.top="'View Details'"
                v-if="moduleId != 28 || (moduleId == 28 && !isInPendingTab)"
                @click="openModalAddRound(item)"
                variant="info"
                class="cursor-pointer"
              >
                {{ item.sumtr + item.sumeq + item.sumex }}
              </b-badge>
              <b-badge v-else variant="primary">
                {{ item.sumtr + item.sumeq + item.sumex }}
              </b-badge>
            </div>
          </template>
          <template #cell(type_send)="{ item }">
            <div style="margin-top: 5px">
              <b-badge
                :variant="
                  item.type_send === 'PHONE' ? 'light-info' : 'light-primary'
                "
              >
                {{ item.type_send }}
              </b-badge>
            </div>
          </template>
          <template #cell(sent_by)="item">
            <div class="text-center">
              {{ item.item.sent_by || "-" }} <br />
              {{ item.item.date_send | myGlobalDay }}
            </div>
          </template>

          <template #cell(action)="{ item }">
            <div class="d-flex gap-5 justify-content-center">
              <b-button
                size="sm"
                variant="success"
                @click="openModalAddRound(item)"
              >
                <feather-icon icon="SendIcon" size="16" />
                Process
              </b-button>

              <b-button
                size="sm"
                variant="primary"
                @click="
                  openModalEditRoundLetter(
                    item.id,
                    item.client_account_id,
                    item.account,
                    item.client_name
                  )
                "
              >
                <feather-icon icon="EditIcon" size="16" />
                Edit
              </b-button>

              <b-button
                size="sm"
                variant="danger"
                @click="deleteRoundLetter(item.id)"
              >
                <feather-icon icon="TrashIcon" size="16" />
                Delete
              </b-button>
            </div>
          </template>

          <template #cell(tracking)="item">
            <div class="text-center">
              <feather-icon
                class="text-info cursor-pointer"
                v-b-tooltip.hover.right="'Tracking'"
                icon="ListIcon"
                @click="openModalTracking(item.item)"
                size="20"
              />
            </div>
          </template>
        </b-table>
        <!--:::fin table::::-->
      </filter-slot>
    </b-container>
    <modal-tracking
      v-if="modalTrackingState"
      :dataClient="dataClient"
      @hideModal="modalTrackingState = false"
    />
    <modal-process-direct
      v-if="modalProcessDirect"
      :dataClient="dataClient"
      :direct="directType"
      :validedit="validEdit"
      @hideModal="closeModalProcessDirect"
      @update="refreshTable"
    />
    <modal-edit-round-letter
      v-if="modalEditRoundLetterState"
      :idround="ncrId"
      :idaccount="clientAccountId"
      :account="accountId"
      :nameClient="nameClient"
      :direct="true"
      @updateTable="refreshTable"
      @hideModal="modalEditRoundLetterState = false"
    />
    <add-round-modal
      v-if="modalAddRoundLetterState"
      :data-round="itemClient"
      :validate-modal="validateModal"
      :from-grid="true"
      @close="closeRoundModal()"
      @emitOpenModalDone="modalOpenModal"
    />
  </div>
</template>
<script>
import DirectsRoundService from "@/views/specialist-digital/views/directs-round/directs-round.service.js";
import ClientOptions from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import ModalTracking from "@/views/commons/components/letters/components/modal/ModalTracking.vue";
import ModalEditRoundLetter from "@/views/specialist-digital/views/letters-round/components/modal/ModalEditRoundLetter.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ModalProcessDirect from "@/views/specialist-digital/views/directs-round/components/modals/ModalProcessDirect.vue";
import Fields from "../data/fields.data";
import Filters from "../data/filters.data";
import AddRoundModal from "@/views/specialists/sub-modules/analyst-department/views/common/AddRoundModal.vue";
import { mapGetters, mapActions } from "vuex";

import specialistsCounter from "@/views/specialists/specialists-counter";
import analystDepartmentCounter from "@/views/specialists/sub-modules/analyst-department/analyst-department-counter";

export default {
  components: {
    ModalTracking,
    ModalEditRoundLetter,
    ModalProcessDirect,
    AddRoundModal,
    StatusAccount,
  },
  props: {
    statusDirectType: {
      type: String,
    },
  },
  created() {
    this.sortDirection =
      this.isInCompletedTab || this.isInDeletedTab ? "desc" : "asc";
  },
  data() {
    return {
      items: [],
      isBusy: false,
      dataClient: null,
      directType: 5,
      fields: Fields,
      filters: Filters,
      orderBy: 24,
      sortDirection: "asc",
      from: "",
      to: "",
      campo1: "",
      program: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      itemClient: {
        id: null,
        idAccount: null,
        account: null,
        clientName: null,
      },
      modalEditRoundLetterState: false,
      modalProcessDirect: false,
      modalTrackingState: false,
      modalAddRoundLetterState: false,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 25,
      },
      startPage: 0,
      status: null,
      toPage: 0,
      user: "",
      validEdit: null,
      ncrId: "",
      clientAccountId: "",
      accountId: "",
      nameClient: "",
      idEditAccount: null,
    };
  },
  methods: {
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
      A_VALID_DONE_DIRECT:
        "AnalystDepartamentDirectRoundStore/A_VALID_DONE_DIRECT",
    }),
    async myProvider(ctx) {
      const params = {
        name_text:
          this.filterPrincipal.model == "" ? null : this.filterPrincipal.model,
        status: this.setStatus,
        orderby: this.orderBy,
        order: this.sortDirection,
        userid: this.currentUser.user_id,
        roleid: this.currentUser.role_id,
        typeClient: null,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        from: this.getFilterByKey("from").model,
        to: this.getFilterByKey("to").model,
        completed_from: this.getFilterByKey("completed_from").model,
        completed_to: this.getFilterByKey("completed_to").model,
        module:
          this.moduleId == 28
            ? this.getFilterByKey("module").model
            : this.moduleId,
        advisor_id:
          this.currentUser.role_id == 8 ? this.currentUser.user_id : this.user,
        isDeleted: this.isInDeletedTab,
      };
      const data = await DirectsRoundService.getDirectRound(params);
      this.totalRows = data.data.total;
      this.startPage = data.data.from;
      this.toPage = data.data.to;
      this.items = data.data.data || [];
      return this.items;
    },

    getFilterByKey(key) {
      return this.filters.find((filter) => filter.key === `${key}`);
    },
    refreshTable() {
      this.$refs.directsRoundListTable.refresh();
    },

    modalOpenModal(data) {
      this.items.forEach((item) => {
        if (item.id == data.id) {
          this.openModalProcessDirect(item, 0);
        }
      });
    },

    async viewPdf(item) {
      try {
        this.addPreloader();
        let params = {
          file_pdf: item.route_pdf,
        };
        const { data } = await DirectsRoundService.addUrlTemporaryPdf(params);
        window.open(data, "_blank");
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },
    openModalProcessDirect(data, validEdit) {
      this.dataClient = data;
      this.validEdit = validEdit;
      this.directType = data.t_send;
      this.modalProcessDirect = true;
    },

    closeModalProcessDirect(type) {
      if (type == 1) {
        this.modalProcessDirect = false;
      } else {
        this.modalProcessDirect = false;
        this.modalAddRoundLetterState = false;
      }
    },
    openModalTracking(data) {
      this.dataClient = data;
      this.modalTrackingState = true;
    },
    openModalAddRound(data) {
      this.itemClient = {
        sumtr: data.sumtr,
        sumex: data.sumex,
        sumeq: data.sumeq,
        id: data.ncr_letters_id,
        idNrl: data.id,
        idAccount: data.client_account_id,
        idLead: data.lead_id,
        idProgram: data.program_id,
        account: data.account,
        clientName: data.client_name,
        clientData: {
          dob: data.dob,
          address: data.address,
          ssn: data.ssn,
          origin_country: data.origin_country,
        },
        onlyRound: true,
        isDirect: { id: data.id, title: data.title, format: data.format },
      };
      this.idEditAccount = data.id;
      this.A_VALID_DONE_DIRECT(
        data.sumeq + data.sumex + data.sumtr !== 0 &&
          this.isInPendingTab &&
          this.isSpecialist
      );
      this.modalAddRoundLetterState = true;
    },
    closeRoundModal() {
      this.modalAddRoundLetterState = false;
    },
    openModalEditRoundLetter(ncr_id, client_account_id, account, name_client) {
      this.ncrId = ncr_id;
      this.clientAccountId = client_account_id;
      this.accountId = account;
      this.nameClient = name_client;
      this.modalEditRoundLetterState = true;
    },
    async deleteRoundLetter(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const params = {
            id: id,
            user_id: this.currentUser.user_id,
          };
          const response = await ClientOptions.deleteRoundLetter(params);
          if (response.status === 200) {
            specialistsCounter(); //update counter specialists
            analystDepartmentCounter();
            this.showSuccessSwal();
            this.refreshTable();
          }
        }
      } catch (error) {
        console.error("Error tring to delete a round letter");
      }
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
      G_VALID_DONE_DIRECT:
        "AnalystDepartamentDirectRoundStore/G_VALID_DONE_DIRECT",
    }),
    isInCompletedTab() {
      return this.$route.meta.typeTab === "completed";
    },
    isInPendingTab() {
      return this.$route.meta.typeTab === "pending";
    },
    isInDeletedTab() {
      return this.$route.meta.typeTab === "deleted";
    },

    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },

    setStatus() {
      if (this.isInCompletedTab) return 7;
      if (this.isInPendingTab) return 1;
      return null;
    },

    filteredFields() {
      this.filterColumn("advisor_name", false);
      this.filterColumn("deleted_by", this.isInDeletedTab);
      this.filterColumn("sent_by", this.isInCompletedTab);
      this.filterColumn("action", this.isInPendingTab);
      this.filterColumn("tracking", this.isInCompletedTab);
      if (![28, 25].includes(this.moduleId)) {
        this.filterColumn("action", false);
        this.filterColumn("tracking", false);
      }
      return this.fields.filter((field) => field.visible);
    },
    validateModal() {
      return !this.isSpecialist || this.isInCompletedTab || this.isInDeletedTab
        ? 3
        : 0;
    },
    isSpecialist() {
      return [28, 25].includes(this.$route.matched[0].meta.module);
    },
    isAdvisor() {
      return [3].includes(this.currentUser.role_id);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    filtersC() {
      if (![28, 25].includes(this.moduleId)) {
        this.getFilterByKey("module").visible = false;
      }
      this.getFilterByKey("completed_from").model = null;
      this.getFilterByKey("completed_to").model = null;
      this.getFilterByKey("completed_from").visible = this.isInCompletedTab;
      this.getFilterByKey("completed_to").visible = this.isInCompletedTab;
      return this.filters.filter((filter) => filter.visible);
    },
  },
  watch: {
    updateLetterTable(newState) {
      if (newState) {
        this.$refs.directsRoundListTable.refresh();
        this.A_UPDATE_TABLE(false);
      }
    },
    items: {
      handler(newState) {
        if (this.idEditAccount != null) {
          newState.forEach((item) => {
            if (item.id == this.idEditAccount) {
              item.sumtr + item.sumex + item.sumeq == 0
                ? this.A_VALID_DONE_DIRECT(false)
                : this.A_VALID_DONE_DIRECT(true);
            }
          });
        }
      },
      inmediate: true,
    },
  },
};
</script>
<style>
.thTable div {
  text-align: center;
  min-height: 27px;
  display: flex;
  align-items: center;
}
.textLogos {
  height: 28px;
  margin-right: 5px;
}
.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  width: 28px;
  height: 28px;
}
</style>
